import React from 'react';
import { Trans } from 'react-i18next';
import './MuteBanner.css';

const MuteBanner = (props) => {
    return (
        <div className="MuteBanner">
            <Trans>mute.headline</Trans>
            <button className="unmute-link" onClick={props.muteFunction} href="#"><Trans>mute.cta</Trans></button>
        </div> 
    );
}

export default MuteBanner;