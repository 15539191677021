import React from 'react';
import PlayerDescription from '../player/PlayerDescription';
import PodcastEpisodes from './PodcastEpisodes';
import './Podcast.css';
import PlayerHeader from '../player/PlayerHeader';

function Podcast({ podcast, episodes = [], deeplinkenabled }) {
    //const deepLinkUrl = Constants.deepLinkPodcast(podcast.id)

    return (
        <div className="Podcast">
            <PlayerHeader
                imageURL={podcast.thumbnail}
                imageTitle={podcast.title}
            >
                <h1 className="podcast-title">{podcast.title}</h1>
                <p className="podcast-publisher">
                    <a href={podcast.website} target="earli_podcast">
                        {podcast.author}
                    </a>
                </p>
            </PlayerHeader>
            <PlayerDescription text={podcast.description} />
            <PodcastEpisodes episodes={episodes} />
        </div>
    );
}

Podcast.defaultProps = {
    podcast: {},
    episodes: [],
    deeplinkenabled: true,
};

export default Podcast;
