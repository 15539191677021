import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LandingPage.css';
import Footer from './components/Footer';
import { trackPageView } from './lib/Tracking';

function LandingPage({ children }) {

    // translation hook
    const { t } = useTranslation();
    // adjust the meta tags with the translated content
    useEffect(() => {
        const description = document.querySelector('meta[name="description"]');
        if (description) {
            description.setAttribute('content', t('head.description'));
        }
        document.title = t('head.title');
    }, [t]);

    // track the page load
    const location = window.location;
    useEffect(() => {
        trackPageView();
    }, [location]);

    return (
        <div className="LandingPage">
            {children}
            <Footer />
        </div>
    );
}

export default LandingPage;
