import React from 'react';
import './Footer.css';
import { Trans } from 'react-i18next';

function Footer(params) {
    function openCMP() {
        if (window._sp_)
          window._sp_.loadPrivacyManagerModal(377763);
    }

    return (
        <footer className="Footer padding-sides bg-dark">
            <br />
            <br />
            <a
                href="https://s3-eu-west-1.amazonaws.com/yapa-legal/privacy.html"
                rel="nofollow"
            >
                <Trans>footer.privacy</Trans>
            </a>
            <br />
            <button
                onClick={openCMP}
                rel="nofollow"
            >
                <Trans>footer.privacy-settings</Trans>
            </button>
            <br />
            <sub>Copyright 2018, upday GmbH & Co. KG</sub>
        </footer>
    );
}

export default Footer;
