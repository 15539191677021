import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PodcastPlayerPage from './PodcastPlayerPage';
import EpisodePlayerPage from './EpisodePlayerPage';
import LandingPage from './LandingPage';
import ScrollToTop from './lib/ScrollToTop';
import { searchParamsObj } from './lib/Utils';
import PlayerError from './components/player/PlayerError';

function App() {
    const { id } = searchParamsObj();

    return (
        <Router>
            <main>
                <ScrollToTop />
                <Switch>
                    {/* TODO: Remove code for below link after approval */}
                    {/* <Route path="/podcast">
                        <PodcastPlayerPage />
                    </Route> */}
                    <Route path="/player">
                        <EpisodePlayerPage />
                    </Route>
                    <Route path="/collection">
                        {/* currently the player has no support for collections  */}
                        <LandingPage>
                            <PlayerError
                                error={{ status: 404 }}
                                type={'collection'}
                            />
                        </LandingPage>
                    </Route>
                    <Route path="/">
                        {/* currently the player is still routing to the root with the id= query  */}
                        {id ? <EpisodePlayerPage /> : <LandingPage />}
                    </Route>
                </Switch>
            </main>
        </Router>
    );
}

export default App;
