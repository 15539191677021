// Tracking set up
// detect if we are running on the production domains

import Constants from "./Constants";

// https://earli-webplayer.upday.com or https://earli.audio/
let config = {
    production: {
        appId: 'earli-web-prod',
        trackerUrl: 'com-upday-main.collector.snplow.net',
    },
    staging: {
        appId: 'earli-web-staging',
        trackerUrl: 'com-upday-qa1.collector.snplow.net',
    },
};
let trackerConfig = Constants.isProduction ? config.production : config.staging;
// initialize the tracker
(function () {
    if (!window || !window.snowplow) {
        return;
    }
    window.snowplow('newTracker', 'cf', trackerConfig.trackerUrl, {
        appId: trackerConfig.appId,
        cookieDomain: window.location.hostname,
    });
    //  track link clicks
    window.snowplow('enableLinkClickTracking');
})();

function trackEvent(schemaID, dataObj) {
    if (window.snowplow) {
        window.snowplow('trackSelfDescribingEvent', {
            schema: schemaID,
            data: dataObj,
        });
    }
    if (window.gtag) {
        // parse the event name from the snowplow schema
        // e.g. 'seek' from 'iglu:com.upday_earli/seek/jsonschema/1-1-0'
        const schemaParts = schemaID.split('/');
        window.gtag('event', schemaParts ? schemaParts[1] : schemaID, dataObj);
    }
}

function trackPageView() {
    if (window.snowplow) {
        window.snowplow('trackPageView');
    }
}

export { trackEvent, trackPageView };
