import React, { useEffect, useState } from 'react';
import Player from './components/player/Player';
import PlayerLoading from './components/player/PlayerLoading';
import PlayerError from './components/player/PlayerError';
import LandingPage from './LandingPage';
import { getEpisode, getPodcast, getPodcastEpisodes } from './lib/APILoader.js';
import { searchParamsObj, getPlatformOS } from './lib/Utils';
import PodcastEpisodes from "./components/podcast/PodcastEpisodes";
import AdSlot from './components/AdSlot';
import "./EpisodesPlayerPage.css";

function EpisodePlayerPage(props) {
    const { id, embed } = searchParamsObj();

    if (embed === 'true') {
        window.document.body.style.backgroundColor = 'transparent';
    }

    // get the data for the episode
    const [episode, setEpisode] = useState();
    const [episodes, setEpisodes] = useState();
    const [error, setError] = useState(null);
    const [podcastId, setPodcastId] = useState();
    const [podcast, setPodcast] = useState();

    // the page looks different on mobile and desktop
    const isMobile = getPlatformOS(navigator.userAgent) !== 'desktop';

    // get the data for the Episode
    useEffect(() => {
        if (!id) {
            const error = { status: 404 };
            setError(error);
        }
        getEpisode(id)
            .then((response) => {
                document.title = `${response.title} – ${response.podcastTitle}`;
                setEpisode(response);
                setPodcastId(response.podcastId);
            })
            .catch(function (error) {
                setError(error);
            });
    }, [episode, id]);

    // get podcast data by podcast id
    useEffect(() => {
        if (!podcastId) return;
        getPodcast(podcastId)
            .then((response) => {
                setPodcast(response);
            })
    }, [podcastId]);

    // get latest episodes data by podcast id
    useEffect(() => {
        if (!podcastId) return;
        getPodcastEpisodes(podcastId).then((response) => {
            const tempEpisodes = response.filter(el=>el.id !== episode.id)
            setEpisodes(tempEpisodes);
        });
    }, [podcastId, episode]);

    function playerGenerator() {
        if (error) {
            return <PlayerError error={error} />;
        } else if (episode) {
            return <Player episode={episode} podcast={podcast} deeplinkenabled={isMobile} />;
        } else {
            // display the loading state of the player
            return <PlayerLoading />;
        }
    }

    function latestEpisodesGenerator() {
        if (error || !episodes || !episodes.length) return null;
        return <PodcastEpisodes episodes={episodes} />
    }

    return (
        <div className='EpisodesPlayerPage'>
            {embed === 'true' ?
                playerGenerator() :
                <LandingPage>
                    {playerGenerator()}
                    {latestEpisodesGenerator()}
                    <AdSlot />
                </LandingPage>}
        </div>
    )
}

export default EpisodePlayerPage;
