// track and forward the refering campaign params for the marketing team
function urlParam(paramName) {
    return new URLSearchParams(window.location.search).get(paramName);
}

const isProduction = /earli-webplayer.upday|earli.audio/i.test(
    window.location.host
);

const apiUrl = {
    prod: 'https://earli.upday.com/api/v1',
    staging: 'https://earli-staging.upday.com/api/v1',
}

const appUrl = () => isProduction ? 'https://earli.audio' : 'https://earli-webplayer-staging.upday.com';

const utm = {
    get campaign() {
        return urlParam('utm_campaign') || 'webplayer';
    },
    get source() {
        return urlParam('utm_source') || 'upday';
    },
    get medium() {
        return urlParam('utm_medium') || 'webplayer';
    },
}

const utmParams = () => `utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`;

// Apple AppStore link params
const appStore = {
    url: 'https://apps.apple.com/app/id1468095591?mt=8',
    providerToken: '',
};
// Google Playstore link params
const playStore = {
    url: 'https://play.google.com/store/apps/details?id=de.axelspringer.yapa',
};
const Constants = {
    get appUrlApple() {
        return `${appStore.url}&ct=${utm.campaign}&pt=${appStore.providerToken}`;
    },
    get appUrlAppleBanner() {
        return `${appStore.url}&ct=${
            utm.campaign
        }&pt=${'download_banner'}`;
    },
    get appUrlGoogle() {
        return `${playStore.url}&referrer=utm_source%3D${utm.source}%26utm_medium%3D${utm.medium}%26utm_campaign%3D${utm.campaign}`;
    },
    get appUrlGoogleBanner() {
        return `${playStore.url}&referrer=utm_source%3D${
            utm.source
        }%26utm_medium%3D${
            utm.medium
        }%26utm_campaign%3D${'download_banner'}`;
    },
    deepLinkEpisode: (episode) =>
        `https://earli.page.link/?link=${encodeURIComponent(
            `${appUrl()}/player?id=${episode.id}&${utmParams()}`
        )}&apn=de.axelspringer.yapa&amv=117&isi=1468095591&ct=${
            utm.campaign
        }&pt=${appStore.providerToken}`,
    deepLinkPodcast: (podcast) =>
        `https://earli.page.link/?link=${encodeURIComponent(
            `${appUrl()}/podcast?id=${podcast.id}&${utmParams()}`
        )}&apn=de.axelspringer.yapa&amv=117&isi=1468095591&ct=${
            utm.campaign
        }&pt=${appStore.providerToken}`,
    isProduction,
    apiUrl,
};

export default Constants;
