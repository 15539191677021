import React, { useState, useEffect, useRef } from 'react';
import './Player.css';
import StreamPlayer from '../../lib/StreamPlayer';
import PlayerDescription from './PlayerDescription';
import PlayerControls from './PlayerControls';
import PlayerScrubber from './PlayerScrubber';
import { trackEvent } from './../../lib/Tracking';
import PlayerHeader from './PlayerHeader';
import { ReactComponent as UnmuteButton } from '../../images/button-unmute.svg';
import { ReactComponent as MuteButton } from '../../images/button-mute.svg';
import MuteBanner from './MuteBanner';
import { searchParamsObj } from './../../lib/Utils';

function useSpaceKey(onKeyPress) {
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.code === 'Space') {
                onKeyPress(e);
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyPress]);
}


function Player({ episode, podcast, deeplinkenabled }) {
    const { embed } = searchParamsObj();
    const isEmbed = embed === 'true'
    const isSafari = navigator.userAgent.toLocaleLowerCase().match(/(ios|iphone|ipad|firefox)/);
    const autoplay = !isSafari && !isEmbed;

    function onStreamProgress(seconds) {
        setStreamPosition(seconds);
    }

    function onStreamStatus(newStatus) {
        setStreamStatus(newStatus);
    }

    const [streamPosition, setStreamPosition] = useState(0);
    const [streamStatus, setStreamStatus] = useState(autoplay ? 'loading' : 'paused');
    const [muted, setMuted] = useState(autoplay);
    const [streamPlayer, setSstreamPlayer] = useState({ empty: true });
    const ref = useRef();

    useEffect(() => {
        if (!ref.current || !streamPlayer.empty) {
            return
        }

        if (autoplay) {
            trackEvent('iglu:com.upday_earli/play/jsonschema/2-0-0', {
                id: episode.id,
                position: Math.floor(streamPosition * 1000),
                duration: episode.durationSeconds * 1000,
                intend: 'auto'
            });
        }

        setSstreamPlayer(StreamPlayer(
            ref.current,
            episode.audio,
            onStreamProgress,
            onStreamStatus,
            autoplay,
        ));
    }, [autoplay, episode, ref, setSstreamPlayer, streamPlayer, streamPosition]);

    // track the listening time
    let [listeningTimeObj, setListeningTimeObj] = useState(null);
    // initialize the listening time reference on each play event
    const initListeningTime = () => {
        setListeningTimeObj({
            id: episode.id,
            start: Math.round(streamPosition),
            log: Date.now(),
        });
    };
    // send the listening time data to the analytics
    const tracklisteningTime = () => {
        if (listeningTimeObj) {
            // calculate how many seconds were listened
            const listenTime = Math.round(
                (Date.now() - listeningTimeObj.log) / 1000
            );
            // TODO GA listen time tracking
            // Snowplow listen time tracking
            trackEvent('iglu:com.upday_earli/play_time/jsonschema/2-0-0', {
                episodeId: episode.id,
                startPoint: listeningTimeObj.start,
                duration: listenTime,
                backfilled: false,
            });
            // reset the listening time
            setListeningTimeObj(null);
        }
    };
    // observe the player state and trigger time tracking events accordingly
    useEffect(() => {
        if (streamStatus === 'playing') {
            initListeningTime();
        } else {
            tracklisteningTime();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamStatus]);

    // sync the play initial play state, just in case we are returning to the page
    if (streamStatus !== streamPlayer.status) {
        // onStreamStatus(streamPlayer.status);
    }

    // if users tap on space, toggle the player
    function onSpaceKeyPress(e) {
        if (streamStatus === 'playing') {
            streamPlayer.pause();
        } else {
            streamPlayer.play();
        }
        e.preventDefault();
    }

    useSpaceKey(onSpaceKeyPress);

    const onPlayClick = () => {
        streamPlayer.play();

        trackEvent('iglu:com.upday_earli/play/jsonschema/2-0-0', {
            id: episode.id,
            position: Math.floor(streamPosition * 1000),
            duration: episode.durationSeconds * 1000,
            intend: 'direct'
        });
    };
    const onPauseClick = () => {
        streamPlayer.pause();

        trackEvent('iglu:com.upday_earli/pause/jsonschema/1-1-0', {
            id: episode.id,
            position: Math.floor(streamPosition * 1000),
            duration: episode.durationSeconds * 1000,
        });
    };

    const onMuteClick = () => {
        streamPlayer.toggleMute();
        setMuted(streamPlayer.isMuted());
    };

    const seekHandler = (seekPosition) => {
        // if for some reason the search position is out bounds
        // clamp it back into the range 0...1
        const seekPositionNormalized = Math.min(Math.max(seekPosition, 0), 1);

        streamPlayer.seekRelative(seekPositionNormalized);

        const seekPositionInEpisode =
            episode.durationSeconds * seekPositionNormalized;

        trackEvent('iglu:com.upday_earli/seek/jsonschema/1-1-0', {
            id: episode.id,
            start: Math.floor(streamPosition * 1000),
            end: Math.floor(seekPositionInEpisode * 1000),
        });
    };

    const podcastExternalUrl = podcast.website;


    return (
        <div className="Player">
            {muted && isEmbed ? <MuteBanner muteFunction={onMuteClick} /> : ''}
            <PlayerHeader
                imageURL={episode.image}
                imageTitle={episode.podcastTitle}
            >
                <h1 className="episode-title">{episode.title}</h1>
                <p className="episode-publisher">
                    <span> {episode.podcastTitle}</span>
                </p>
                <p className="episode-author">
                    <a href={podcastExternalUrl} target="_blank" rel="noopener noreferrer"><button>{episode.author}</button></a>
                </p>
            </PlayerHeader>
            {
                isSafari
                    ?
                    <audio autoPlay muted ref={ref} style={{ height: 0, width: 0 }} />
                    :
                    <video autoPlay muted ref={ref} style={{ height: 0, width: 0 }} />
            }

            <div className="DragContainer">
                <PlayerControls
                    controlState={streamStatus}
                    onPlay={onPlayClick}
                    onPause={onPauseClick}
                />
                <PlayerScrubber
                    position={streamPosition}
                    duration={episode.durationSeconds}
                    status={streamStatus}
                    onSeek={seekHandler}
                />
                {muted ?
                    <UnmuteButton
                        className={"MuteButton"}
                        onClick={onMuteClick}
                    /> :
                    <MuteButton
                        className={"MuteButton"}
                        onClick={onMuteClick}
                    />}
            </div>
            {isEmbed ? null : (
                <>
                    <PlayerDescription text={episode.summary || ' '} />
                </>
            )}
        </div>
    );
}

Player.defaultProps = {
    episode: {},
    podcast: {},
};

export default Player;
