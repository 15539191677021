import { Promise } from 'q';
import Constants from './Constants';

const authToken =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmMjRlMjRkYS03OTdkLTRmOTMtOTcwNS04NDNmNDllYjVkZGUifQ.LO1D1A0KB5iVY8ieCPktS902AhghwaoyywjxOewZ1aUIOeW1ELZLAoaBq0rVj9JNkqcXq7DikoJyoM267NpyPw';

let cache = {};

function APIURL(path) {
    if (!path) {
        throw Error(`APIURL requires a 'path' parameter`);
    }
    const apiUrl = Constants.isProduction ? Constants.apiUrl.prod : Constants.apiUrl.staging;
    return `${apiUrl}${path}`;
}

function requestAPI(url) {
    return fetch(url, {
        crossDomain: true,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });
}

function getObj(path) {
    if (!path) {
        throw Error(`getObj requires a 'path' parameter`);
    }

    if (cache[path]) {
        return Promise.resolve(cache[path]);
    }

    return requestAPI(APIURL(path))
        .then((response) => response.json())
        .then((apiObj) => {
            cache[path] = apiObj;
            return apiObj;
        });
}

function getEpisode(episodeId) {
    return getObj(`/episodes/${episodeId}`);
}

function getPodcastEpisodes(podcastId) {
    return getObj(`/episodes/search/findByPodcastId?id=${podcastId}`).then(
        (response) => {
            return response['_embedded'].episodes;
        }
    );
}

function getPodcast(podcastId) {
    return getObj(`/podcasts/${podcastId}`);
}

export { getEpisode, getPodcast, getPodcastEpisodes };
