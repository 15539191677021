import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { epochToDateStr } from '../../lib/Utils';
import './PodcastEpisodes.css';
import { ReactComponent as ButtonPlay } from '../../images/button-play.svg';
function PodcastEpisodes({ episodes = [] }) {
    const [showAllExpisodes, setShowAllExpisodes] = useState(false);

    const episodeItems = episodes.map((episode, index) => {
        return (
            (showAllExpisodes && index < 6) || index < 3 ?
                <li className='podcast-episodes-list-item' key={episode.id}>
                    <PodcastEpisodeItem episode={episode} />
                </li> : null
        );
    });

    const toggleMoreButton = () => {
        setShowAllExpisodes(!showAllExpisodes);
    };

    const toggleButtonLabel = showAllExpisodes ? 'Less' : 'More';

    return (
        <div className="PodcastEpisodes">
            <h3>Latest Episodes:</h3>
            <ul className="podcast-episodes-list">{episodeItems}</ul>
            <div className='episode-description-toggle'>
                <button className='button-link' onClick={toggleMoreButton}>
                    {toggleButtonLabel}
                </button>
            </div>
        </div>
    );
}

PodcastEpisodes.defaultProps = {
    podcast: {},
    episodes: [],
};

function PodcastEpisodeItem({ episode }) {
    const dateStr = epochToDateStr(episode.publicationEpoch);
    const durationStr = [Math.floor(episode.durationSeconds / 60), 'min'].join(
        ' '
    );
    return (
        <a href={'/?id=' + episode.id}>
            <ButtonPlay/>
            <div>
                <b className="episode-title">{episode.title}</b>
                <span className="episode-publication-date">{dateStr}</span>{' '}
                <span className="episode-duration">{durationStr}</span>
            </div>
        </a>
    );
}

export default PodcastEpisodes;
