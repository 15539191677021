import React from 'react';
import PlayerDescription from './PlayerDescription';
import PlayerControls from './PlayerControls';
import PlayerScrubber from './PlayerScrubber';
import PlayerArtwork from './PlayerArtwork';

function PlayerLoading() {
    function LoadingTitle({ titleText }) {
        return <h1 className="episode-title">{titleText}</h1>;
    }
    return (
        <div className="Player PlayerLoading">
            <div className="PlayerHeader">
                <PlayerArtwork />
                <div className="headlines">
                    <LoadingTitle />
                    <p className="episode-publisher"></p>
                </div>
            </div>
            <PlayerScrubber />
            <PlayerControls controlState={'loading'} />
            <PlayerDescription />
        </div>
    );
}

export default PlayerLoading;
