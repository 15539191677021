let stream;
let status = 'paused';

var onStreamProgress = () => {},
    onStreamStatusChange = () => {};

const updateStatus = (newStatus) => {
    status = newStatus;
    onStreamStatusChange(status);
};

const processEvent = (event) => {
    // console.log('-----processEvent', event.type, stream.readyState)
    // try to reduce the amount of possible player states
    // to 4: paused, playing, loading, error
    if (stream.paused) {
        updateStatus('paused');
        return;
    }

    if (event.type === 'waiting' && !stream.paused) {
        updateStatus('loading');
        return;
    }
    // when it had to be preloaded
    if (
        event.type === 'canplaythrough' &&
        !stream.paused &&
        stream.readyState === 4
    ) {
        updateStatus('playing');
        return;
    }

    if (event.type === 'playing' && !stream.paused && stream.readyState === 3) {
        updateStatus('loading');
        return;
    }

    // on mobile browsers
    if (event.type === 'playing' && !stream.paused && stream.readyState === 4) {
        updateStatus('playing');
        return;
    }

    if (event.type === 'error') {
        updateStatus('error');
        return;
    }
};


const addListeners = () => {
    stream.addEventListener('play', processEvent);
    stream.addEventListener('playing', processEvent);
    stream.addEventListener('canplaythrough', processEvent);
    stream.addEventListener('loadstart', processEvent);
    stream.addEventListener('pause', processEvent);
    stream.addEventListener('waiting', processEvent);
    stream.addEventListener('loadstart', processEvent);
    stream.addEventListener('loadeddata', processEvent);
    stream.addEventListener('loadedmetadata', processEvent);
    stream.addEventListener('error', processEvent);
    stream.addEventListener('timeupdate', (event) => {
        onStreamProgress(stream.currentTime);
    });
}

// pause the stream when closing the window, solves iOS issue
window &&
    window.addEventListener('beforeunload', (event) => {
        stream.pause();
    });

const play = () => {
    stream.play();
};
const pause = () => {
    stream.pause();
};

// Added mute button for (temporary) autoplay hotfix
const toggleMute = () => stream.muted = !stream.muted;

// Identify if the stream is currently muted
export const isMuted = () => stream.muted;

const seekRelative = (relativePosition) => {
    stream.currentTime = stream.duration * Math.min(relativePosition, 1);
};

function StreamPlayer(streamRef, url, progressHandler, statusHandler, autoplay) {
    if (!stream) {
        stream = streamRef;
        addListeners()
    }
    // don't reload the stream if it's already in
    if (url && url !== stream.src) {
        stream.muted = autoplay; 
        stream.autoplay = autoplay;
        stream.preload = autoplay ? 'auto' : 'none'; 
        stream.src = url;
        status = autoplay ? 'playing' : 'paused';
    }

    if (typeof progressHandler === 'function') {
        onStreamProgress = progressHandler;
    }

    if (typeof statusHandler === 'function') {
        onStreamStatusChange = statusHandler;
    }

    return {
        play: play,
        pause: pause,
        toggleMute: toggleMute,
        seekRelative: seekRelative,
        status: status,
        currentTime: stream.currentTime,
        isMuted,
    };
}

export default StreamPlayer;
