import React, { useState } from 'react';
import './PlayerArtwork.css';

function PlayerArtwork(props) {
    const [loaded, setLoaded] = useState(false);
    const onLoadSuccess = () => {
        // if the https image fails, load the original then
        setLoaded(true);
    };
    let imageSource = props.src;
    // use only the original image source in the already optimized images
    // https://updaymedia.atlassian.net/browse/YM-1454
    if (imageSource) {
        imageSource = imageSource.replace(/^https?:.*https?:\/\//, 'https://');
    }
    const cloudinaryPrefix =
        'https://earli-assets.upday.com/image/fetch/q_auto:eco,w_240,h_240/';
    const imageURl = cloudinaryPrefix + encodeURIComponent(imageSource);

    return (
        <div className="PlayerArtwork">
            {props.src ? (
                <img
                    src={imageURl}
                    className={loaded ? 'loaded' : ''}
                    onLoad={onLoadSuccess}
                    alt={props.alt}
                />
            ) : (
                ''
            )}
        </div>
    );
}

export default PlayerArtwork;
