import React from 'react';
import './PlayerError.css';
import { ReactComponent as BackgroundError } from '../../images/bg-error.svg';
import { useTranslation } from 'react-i18next';

function PlayerError(props) {
    const { t } = useTranslation();

    let errorMessage = t('error.generic');
    if (props.error.status === 404) {
        errorMessage = t('error.notfound');
    }

    if (props.type === 'collection') {
        errorMessage =
            "This link isn't supported in the Web player. Please download the earliAudio app to use the collection";
    }
    return (
        <div className="PlayerError">
            <div>
                <BackgroundError />
            </div>
            <h3>
                {t('error.headline')} {props.error.status}...
            </h3>
            <p>{errorMessage}</p>
        </div>
    );
}

PlayerError.defaultProps = {
    error: {
        status: 0,
    },
};

export default PlayerError;
