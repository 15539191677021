import React from 'react';
import PlayerArtwork from '../player/PlayerArtwork';
import PlayerDescription from '../player/PlayerDescription';
import PodcastEpisodes from './PodcastEpisodes';
import './Podcast.css';

function PodcastLoading() {
    function LoadingTitle({ titleText }) {
        return <h1 className="podcast-title">{titleText}</h1>;
    }
    return (
        <div className="Podcast PodcastLoading">
            <div className="PlayerHeader">
                <PlayerArtwork />
                <div className="headlines">
                    <LoadingTitle />
                    <p className="podcast-publisher"></p>
                </div>
            </div>
            <PlayerDescription />
            <PodcastEpisodes />
        </div>
    );
}

PodcastLoading.defaultProps = {
    podcast: {},
    episodes: [],
    deeplinkenabled: true,
};

export default PodcastLoading;
