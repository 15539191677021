import React from 'react';
import './PlayerControls.css';
import { ReactComponent as ButtonSpinner } from '../../images/button-spinner.svg';
import { ReactComponent as ButtonPlay } from '../../images/button-play.svg';
import { ReactComponent as ButtonPause } from '../../images/button-pause.svg';
import { searchParamsObj } from '../../lib/Utils';

function PlayerControls(props) {
    const { embed } = searchParamsObj();
    const isEmbed = embed === 'true'

    const reloadPage = () => {
        window.location.reload();
    };

    const controlButton = () => {
        switch (props.controlState) {
            case 'loading':
                return <ButtonSpinner className="button-spinner" />;
            case 'playing':
                return <ButtonPause onClick={props.onPause} />;
            case 'error':
                return <h1 onClick={reloadPage}>:(</h1>;
            default:
                return <ButtonPlay onClick={props.onPlay} />;
        }
    };

    return (
        <div className={`PlayerControls ${isEmbed ? 'embed' : ''}`}>
            {controlButton()}
        </div>
    );
}

PlayerControls.defaultProps = {
    controlState: 'paused',
    onPause: () => {
        console.log('PlayerControls pause clicked');
    },
    onPlay: () => {
        console.log('PlayerControls play clicked');
    },
};

export default PlayerControls;
