import React from 'react';
import PlayerArtwork from './PlayerArtwork';
import './PlayerHeader.css';

const PlayerHeader = (props) => {
    return (
        <div className="PlayerHeader">
            <PlayerArtwork src={props.imageURL} alt={props.imageTitle} />
            <div className="headlines">{props.children}</div>
        </div>
    );
};

export default PlayerHeader;
