import React, { useState, useEffect } from 'react';
import LandingPage from './LandingPage';
import Podcast from './components/podcast/Podcast';
import PlayerError from './components/player/PlayerError';
import PodcastLoading from './components/podcast/PodcastLoading';
import { getPodcast, getPodcastEpisodes } from './lib/APILoader.js';
import { searchParamsObj } from './lib/Utils';
import { getPlatformOS } from './lib/Utils';

function PodcastPlayerPage(props) {
    const { id } = searchParamsObj();

    // get the data for the podcast
    const [podcast, setPodcast] = useState();
    const [episodes, setEpisodes] = useState();
    const [error, setError] = useState(null);

    const isMobile = getPlatformOS(navigator.userAgent) !== 'desktop';

    useEffect(() => {
        if (!id) {
            const error = { status: 404 };
            setError(error);
        }
        getPodcast(id)
            .then((podcast) => {
                document.title = `Podcast earli Audio: ${podcast.title}`;
                setPodcast(podcast);
                return podcast;
            })
            .then((podcast) => {
                getPodcastEpisodes(podcast.id).then((episodes) => {
                    setEpisodes(episodes.length && episodes.slice(0, 4));
                });
            })
            .catch(function (error) {
                setError(error);
            });
    }, [podcast, id]);

    function playerGenerator() {
        if (error) {
            return <PlayerError error={error} />;
        } else if (podcast && episodes) {
            return (
                <Podcast
                    podcast={podcast}
                    episodes={episodes}
                    deeplinkenabled={isMobile}
                />
            );
        } else {
            return <PodcastLoading />;
        }
    }

    return <LandingPage>{playerGenerator()}</LandingPage>;
}

export default PodcastPlayerPage;
