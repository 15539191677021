import React from 'react';

let slot;

const useDfpSlot = ({ path, size, id }) => {
    React.useEffect(() => {
        const googletag = window.googletag || {};
        if (slot) {
            const slotContainer = document.getElementById(
                'div-gpt-ad-1594719830582-0'
            );
            // in case we have returned to the page e.g. from podcast overview
            //  and it's still empty, then refresh the ads
            if (slotContainer && slotContainer.childNodes.length === 0) {
                googletag.pubads().refresh([slot]);
            }
            return;
        }
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(function () {
            googletag.pubads().collapseEmptyDivs(true);
            slot = googletag
                .defineSlot(path, size, id)
                .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
        });
        googletag.cmd.push(function () {
            googletag.display(id);
        });
    }, [path, size, id]);
};

const AdSlot = () => {
    const slots = {
        prod: {
            path: '/21675937462/earliaudio',
            id: 'div-gpt-ad-1594808570899-0',
        },
        staging: {
            path: '/21675937462/earli_test1',
            id: 'div-gpt-ad-1594719830582-0',
        },
    };

    const slot =
        window.location.host === 'earli.audio' ? slots.prod : slots.staging;
    useDfpSlot({
        path: slot.path,
        size: [[320, 100], [320, 480], [300, 150], [300, 250], [300, 600], [320, 50]],
        id: slot.id,
    });
    return (
        <div
            className="AdSlot"
            id={slot.id}
            style={{
                margin: '23px 30px auto 30px',
            }}
        />
    );
};

export default AdSlot;
