import React, { useState, useEffect } from 'react';
import './PlayerDescription.css';
let descriptionRef = React.createRef();

const PlayerDescription = (props) => {
    const [descriptionExpanded, toggleDescriptionState] = useState(false);
    const [maxDescriptionHeight, setMaxDescriptionHeight] = useState(480);
    const [hideToggleButton, setHideToggleButton] = useState(true);

    // API delivers the description as HTML markup
    function createMarkup() {
        return { __html: props.text };
    }

    // we have to render the node first to understand how tall the content will be
    useEffect(() => {
        if (!descriptionRef || !descriptionRef.current) {
            return;
        }
        let descriptionHeight = descriptionRef.current.scrollHeight;
        let visibleHeight = descriptionRef.current.clientHeight;
        let tollerableOverflow = 4;
        let toggleShouldDisplay =
            Math.abs(descriptionHeight - visibleHeight) > tollerableOverflow;
        setHideToggleButton(!toggleShouldDisplay);
        setMaxDescriptionHeight(descriptionHeight);
    }, [props.text]);

    const toggleDescription = () => {
        toggleDescriptionState(!descriptionExpanded);
    };

    const descriptionStyles = descriptionExpanded
        ? { maxHeight: maxDescriptionHeight + 'px' }
        : {};
    const toggleClasses = hideToggleButton
        ? 'button-link hidden'
        : 'button-link';
    const toggleButtonLabel = descriptionExpanded ? 'Less' : 'More';

    return (
        <div className="PlayerDescription">
            <div
                className="episode-description"
                ref={descriptionRef}
                style={descriptionStyles}
                dangerouslySetInnerHTML={createMarkup()}
            ></div>
            <div className="episode-description-toggle">
                <button className={toggleClasses} onClick={toggleDescription}>
                    {toggleButtonLabel}
                </button>
            </div>
        </div>
    );
};

export default PlayerDescription;
