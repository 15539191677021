// convert numbers to padded number strings, eg 1 => '01'
function padNumber(n, width, symbol) {
    symbol = symbol || '0';
    n = n + '';
    return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(symbol) + n;
}

//  convert seconds to timecode strings, eg 1.345 => '00:00:01'
function secondsToTimecode(time) {
    time = Number(time);
    const h = Math.floor(time / 3600);
    const m = Math.floor((time % 3600) / 60);
    const s = Math.floor((time % 3600) % 60);
    return [padNumber(h, 2), padNumber(m, 2), padNumber(s, 2)].join(':');
}

function epochToDateStr(epoch) {
    const date = new Date(epoch * 1000);
    return [
        padNumber(date.getDate(), 2),
        padNumber(date.getMonth() + 1, 2),
        padNumber(date.getFullYear(), 2),
    ].join('-');
}

function queryToObj(searchQuery) {
    let pairs = searchQuery ? searchQuery.replace(/^\?/, '').split('&') : [];
    let result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return JSON.parse(JSON.stringify(result));
}

function searchParamsObj() {
    return (
        queryToObj(window && window.location && window.location.search) || {}
    );
}

function getPlatformOS(userAgent) {
    if (
        /iPhone|iPod|iPad|Macintosh; Intel/.test(userAgent) &&
        !window.MSStream
    ) {
        return 'apple';
    } else if (/wv.*Version\/\d/i.test(userAgent)) {
        // detect Android in-app weviews
        return 'google webview';
    } else if (/android/i.test(userAgent)) {
        return 'google';
    } else {
        return 'desktop';
    }
}

function isHiRes(window) {
    return !!window && !!window.devicePixelRatio && window.devicePixelRatio > 1;
}

export {
    padNumber,
    secondsToTimecode,
    epochToDateStr,
    queryToObj,
    searchParamsObj,
    getPlatformOS,
    isHiRes,
};
