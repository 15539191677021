import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { secondsToTimecode } from '../../lib/Utils';
import './PlayerScrubber.css';

function PlayerScrubber(props) {
    const scrubberWidth = 240;
    const [scrubberPositionX, setScrubberPositionX] = useState(0);
    let isDragged = false;

    useEffect(() => {
        if (!isDragged && props.status !== 'loading') {
            const targetPosition = Math.round(
                (props.position / props.duration) * scrubberWidth
            );
            setScrubberPositionX(targetPosition);
        }
    }, [props, isDragged]);

    // formated timecodes
    const streamPosition = secondsToTimecode(props.position);
    const streamDuration = secondsToTimecode(props.duration);

    const onSeekEvent = (xPos) => {
        setScrubberPositionX(xPos);
        props.onSeek(xPos / scrubberWidth);
    };

    const onStart = (e) => {
        isDragged = true;
    };

    const onStop = (e, ui) => {
        onSeekEvent(ui.x);
        isDragged = false;
    };

    const onTimelineClick = (e) => {
        const clickX = e.pageX - e.target.getBoundingClientRect().x;
        onSeekEvent(clickX);
    };

    return (
        <div className="PlayerScrubber">
            <div
                className="timeline-container"
                onClick={onTimelineClick}
                style={{ height: '12px' }}
            >
                <div className="timeline"></div>
            </div>
            <Draggable
                axis="x"
                bounds="parent"
                position={{ x: scrubberPositionX, y: 0 }}
                onStart={onStart}
                onStop={onStop}
            >
                <div className="playhead"></div>
            </Draggable>

            <div className="time-stamps">
                <div className="time-current">{streamPosition}</div>
                <div className="time-duration">{streamDuration}</div>
            </div>
        </div>
    );
}

PlayerScrubber.defaultProps = {
    position: 0,
    duration: 0,
    onSeek: (position) => {
        console.log('onSeek', position);
    },
};

export default PlayerScrubber;
