import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import translationDE from '../locales/de.json';
import translationPT from '../locales/pt-PT.json';
import translationNL from '../locales/nl.json';
import translationPL from '../locales/pl.json';
import translationES from '../locales/es-ES.json';
import translationIT from '../locales/it.json';

const isDev = process && process.env && process.env.NODE_ENV === 'development';

const translations = {
    en: { translation: translationEN },
    de: { translation: translationDE },
    fr: { translation: translationFR },
    it: { translation: translationIT },
    nl: { translation: translationNL },
    pt: { translation: translationPT },
    es: { translation: translationES },
    pl: { translation: translationPL },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: translations,
        fallbackLng: 'en',
        ns: '',
        //lng: 'pl',
        debug: isDev,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
